// navigation

const headerNavigation = document.getElementById("header-navigation");
const headerNavigationToggle = document.getElementById("header-navigation-toggle");
headerNavigationToggle.addEventListener("click", () => {
    headerNavigation.classList.toggle("expanded");
});
headerNavigation.addEventListener("click", (e) => {
    if (e.target.href) {
        headerNavigation.classList.toggle("expanded");
    }
});

// tally meters

function initiateTally(meter) {
    const textContent = meter.textContent;
    const prefix = textContent.match(/^\D*/);
    const suffix = textContent.match(/\D*$/);

    let value = textContent.match(/\d+((\.|,)\d+)?/)
        ? textContent.match(/\d+((\.|,|\s)\d+)*/)[0]
        : 0;
    const comma = value ? value.match(/,/) : false;
    if (comma) {
        value = value.replace(",", ".");
    }

    const decimals = value && value.match(/\.\d*$/) ? value.match(/\.\d*$/)[0].length - 1 : 0;
    const coercedValue = +value;
    let start, previousTimeStamp;
    let done = false;

    function step(timestamp) {
        if (start === undefined) {
            start = timestamp;
        }
        const elapsed = timestamp - start;

        if (previousTimeStamp !== timestamp) {
            const display = comma
                ? Math.min(0.0005 * elapsed * coercedValue, coercedValue)
                      .toFixed(decimals)
                      .replace(".", ",")
                : Math.min(0.0005 * elapsed * coercedValue, coercedValue).toFixed(decimals);
            meter.textContent = prefix + display + suffix;
            if (display === textContent) done = true;
        }

        if (elapsed < 2000) {
            previousTimeStamp = timestamp;
            if (!done) {
                window.requestAnimationFrame(step);
            }
        }
    }

    if (value) {
        window.requestAnimationFrame(step);
    }
}
if (!matchMedia("(prefers-reduced-motion)").matches) {
    const tallyMeterObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && !entry.target.classList.contains("initiated")) {
                entry.target.classList.add("initiated");
                initiateTally(entry.target);
            }
        });
    });
    const tallyMeters = document.getElementsByClassName("tally-meter");
    for (const tallyMeter of tallyMeters) {
        tallyMeterObserver.observe(tallyMeter);
    }
}

// affiliate funcionality

const affiliates = document.getElementsByClassName("affiliate");

function addModal(affiliate) {
    const affiliateModal = document.createElement("div");
    const affiliateInfoWrapper = document.createElement("div");
    const affiliateImage = document.createElement("img");
    const affiliateName = document.createElement("p");
    const affiliatePosition = document.createElement("p");
    const affiliatePhone = document.createElement("a");
    const affiliateMail = document.createElement("p");
    const affiliateText = document.createElement("p");
    const exitButton = document.createElement("button");

    affiliateName.classList.add("affiliate-modal-name");
    affiliatePosition.classList.add("affiliate-modal-position");
    affiliatePhone.classList.add("affiliate-modal-phone");
    affiliateMail.classList.add("affiliate-modal-mail");
    affiliateText.classList.add("affiliate-modal-text");

    exitButton.textContent = "✕";

    affiliateImage.src = affiliate.firstElementChild.src;
    affiliateName.textContent = affiliate.getElementsByClassName("affiliate-name")[0].textContent;
    affiliatePosition.textContent =
        affiliate.getElementsByClassName("affiliate-position")[0].textContent;
    affiliatePhone.textContent = affiliate.getElementsByClassName("affiliate-phone")[0].textContent;
    affiliateMail.textContent = affiliate.getElementsByClassName("affiliate-mail")[0].textContent;
    affiliateText.textContent = affiliate.getElementsByClassName("affiliate-text")[0].textContent;

    affiliateModal.classList.add("affiliate-modal");
    affiliateInfoWrapper.classList.add("affiliate-info-wrapper");
    affiliateModal.appendChild(affiliateImage);
    affiliateInfoWrapper.appendChild(affiliateName);
    affiliateInfoWrapper.appendChild(affiliatePosition);
    affiliateInfoWrapper.appendChild(affiliatePhone);
    affiliateInfoWrapper.appendChild(affiliateMail);
    affiliateInfoWrapper.appendChild(affiliateText);
    affiliateModal.appendChild(affiliateInfoWrapper);
    affiliateModal.appendChild(exitButton);

    affiliateModal.addEventListener("click", function (e) {
        e.stopPropagation();
        if (e.target === affiliateModal) {
            affiliate.removeChild(affiliateModal);
        }
    });
    exitButton.addEventListener("click", function (e) {
        e.stopPropagation();
        affiliate.removeChild(affiliateModal);
    });
    affiliate.appendChild(affiliateModal);
}
for (const affiliate of affiliates) {
    affiliate.addEventListener("click", () => {
        addModal(affiliate);
    });
}

const affiliateButtonGroups = document.getElementsByClassName("affiliate-selection-buttons");
for (const affiliateButtonGroup of affiliateButtonGroups) {
    const affiliateButtons = affiliateButtonGroup.children;
    const buttonAffiliates = document.getElementById(affiliateButtonGroup.dataset.controls);
    for (const affiliateButton of affiliateButtons) {
        const select = affiliateButton.dataset.select;
        affiliateButton.addEventListener("click", (e) => {
            if (!e.target.classList.contains("active")) {
                affiliateButtonGroup.getElementsByClassName("active")[0].classList.remove("active");
                e.target.classList.add("active");
            }
            // const show = buttonAffiliates.getElementsByClassName(select);
            for (const buttonAffiliate of buttonAffiliates.children) {
                if (buttonAffiliate.classList.contains(select) || !select) {
                    buttonAffiliate.classList.remove("deselected");
                } else {
                    buttonAffiliate.classList.add("deselected");
                }
            }
        });
    }
}

// ticker tapes

const tapes = document.getElementsByClassName("ticker-tape");
if (tapes) {
    for (const tape of tapes) {
        let i = 0;
        const length = tape.children.length - 1;
        while (tape.children.length < 29) {
            tape.append(tape.children[i % length].cloneNode(true));
            i++;
        }
    }
}

//dialogs

const dialogs = document.getElementsByTagName("dialog");
if (dialogs) {
    for (const dialog of dialogs) {
        dialog.getElementsByClassName("close-dialog")[0].addEventListener("click", () => {
            dialog.close();
        });
    }
}
