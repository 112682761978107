const isDev = location.hostname === "localhost" || location.hostname === "127.0.0.1";

window.addEventListener("load", function () {
    const cookieName = "cookie_consent";
    const gtmCode = "GTM-MJN6VRD";

    loadGTM(gtmCode);

    let cookieExists = document.cookie.indexOf(cookieName + "=") != -1;
    if (!cookieExists) {
        pushAnalyticsFire();
    }

    var cookieconsent = initCookieConsent();

    const locale = document.documentElement.getAttribute("lang").slice(-2).toLowerCase();

    const toggles = {
        necessary: {
            value: "necessary",
            enabled: true,
            readonly: true
        },
        functional: {
            value: "functional",
            enabled: false,
            readonly: false
        },
        analytics: {
            value: "analytics",
            enabled: true,
            readonly: false
        },
        marketing: {
            value: "marketing",
            enabled: false,
            readonly: false
        }
    };

    cookieconsent.run({
        current_lang: locale,
        autoclear_cookies: true, // default: false
        page_scripts: true, // default: false
        mode: "opt-in", // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        autorun: true, // default: true
        remove_cookie_tables: false, // default: false
        cookie_name: cookieName, // default: 'cc_cookie'
        cookie_expiration: 90, // default: 182 (days)
        cookie_path: "/", // default: root
        revision: 2, // default: 0

        languages: {
            en: {
                consent_modal: {
                    title: "We use cookies!",
                    description:
                        'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                    primary_btn: {
                        text: "Accept all",
                        role: "accept_all"
                    },
                    secondary_btn: {
                        text: "Reject all",
                        role: "accept_necessary"
                    }
                },
                settings_modal: {
                    title: "Cookie preferences",
                    save_settings_btn: "Save settings",
                    accept_all_btn: "Accept all",
                    reject_all_btn: "Reject all",
                    close_btn_label: "Close",
                    blocks: [
                        {
                            title: "Cookie usage 📢",
                            description:
                                'We and third parties use cookies on our website. We use cookies to keep statistics, to store your preferences and for marketing purposes. By clicking \'Accept\' you agree to the use of all cookies as described in our <a href="/en-GB/disclaimer" class="cc-link">privacy policy</a> and <a href="/en-GB/disclaimer" class="cc-link">privacy policy</a> statement. You can also choose to specify your own preferences.'
                        },
                        {
                            title: "Necessary cookies",
                            description:
                                "These cookies are necessary for the website to function (always on).",
                            toggle: toggles["necessary"]
                        },
                        {
                            title: "Functional cookies",
                            description:
                                "These cookies provide improved functionality of the website.",
                            toggle: toggles["functional"]
                        },
                        {
                            title: "Analytical cookies",
                            description:
                                "These cookies allow us to track relevant information about traffic on our website.",
                            toggle: toggles["analytics"]
                        },
                        {
                            title: "Marketing cookies",
                            description:
                                "These cookies allow us to tailor advertisements to your preferences.",
                            toggle: toggles["marketing"]
                        },
                        {
                            title: "More information",
                            description:
                                'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/en-GB/contact">contact us</a>.'
                        }
                    ]
                }
            },
            nl: {
                consent_modal: {
                    title: "Cookies op onze website",
                    description:
                        "Wij willen graag gebruik maken van cookies om je gebruikerservaring te optimaliseren, onze website te verbeteren en voor marketingdoeleinden. Klik op 'Accepteren' om akkoord te gaan met het gebruik van alle cookies. Klik op 'Weigeren' om alleen cookies te accepteren die noodzakelijk voor het functioneren van de website.  Klik  <button type=\"button\" data-cc=\"c-settings\" class=\"cc-link\">hier</button> meer informatie en beheer van jouw cookievoorkeuren. " , 
                    primary_btn: {
                        text: "Accepteren",
                        role: "accept_all" // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: "Weigeren",
                        role: "accept_necessary" // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: "",
                    save_settings_btn: "Opslaan",
                    accept_all_btn: "Accepteer alles",
                    reject_all_btn: "Weiger alles",
                    close_btn_label: "Sluiten",
                    blocks: [
                        {
                            title: "Cookies op onze website",
                            description:
                                'Wij en derde partijen maken gebruik van cookies om statistieken bij te houden, jouw voorkeuren op te slaan en voor marketingdoeleinden. Door deze cookies te accepteren, ga je akkoord met het gebruik van alle cookies zoals omschreven in onze <a href="/nl-NL/cookieverklaring" class="cc-link">cookieverklaring</a>. Je kunt er ook voor kiezen om je eigen voorkeur op te geven.'
                        },
                        {
                            title: "Noodzakelijke cookies",
                            description:
                                "Deze cookies zijn noodzakelijk voor het functioneren van de website en staan daarom altijd aan.",
                            toggle: toggles["necessary"]
                        },
                        {
                            title: "Functionele cookies",
                            description:
                                "Deze cookies zorgen voor een verbeterde functionaliteit van de website.",
                            toggle: toggles["functional"]
                        },
                        {
                            title: "Analytische cookies",
                            description:
                                "Deze cookies zorgen ervoor dat wij onze website kunnen analyseren en verbeteren.",
                            toggle: toggles["analytics"]
                        },
                        {
                            title: "Marketing cookies",
                            description:
                                "Deze cookies zorgen ervoor dat wij advertenties kunnen afstemmen op jouw voorkeuren.",
                            toggle: toggles["marketing"]
                        },
                        {
                            title: "Meer informatie",
                            description:
                                'Voor vragen met betrekking tot ons beleid inzake cookies en je keuzes, <a class="cc-link" href="/nl-NL/contact">neem contact op</a>.'
                        }
                    ]
                }
            }
        },

        gui_options: {
            consent_modal: {
                layout: "cloud", // box/cloud/bar
                position: "middle center", // bottom/middle/top + left/right/center
                transition: "slide", // zoom/slide
                swap_buttons: true // enable to invert buttons
            },
            settings_modal: {
                layout: "box", // box/bar
                // position: 'left',           // left/right
                transition: "slide" // zoom/slide
            }
        },
        onAccept: function (cookie) {
            decideJS(cookie);
        },
        onChange: function (cookie, changed_categories) {
            decideJS(cookie);
        }
    });
});

function decideJS(cookie) {
    const necessary = cookie.level.includes("necessary");
    const functional = cookie.level.includes("functional");
    const analytics = cookie.level.includes("analytics");
    const marketing = cookie.level.includes("marketing");

    window.dataLayer = window.dataLayer || [];

    if (isDev) {
        console.log("(dev) decideJS()");
    }

    if (analytics) {
        pushAnalyticsFire();
    }

    if (marketing) {
        pushMarketingFire();
    }
}

function pushAnalyticsFire() {
    if (isDev) {
        console.log("(dev) analytics would be fired");
    }

    if (!isDev) {
        dataLayer.push({ event: "event_name_analytics" });
        dataLayer.push({ event: "client-consent-update" });
        
    }
}

function pushMarketingFire() {
    if (isDev) {
        console.log("(dev) marketing would be fired");
    }

    if (!isDev) {
        dataLayer.push({ event: "event_name_marketing" });
        dataLayer.push({ event: "client-consent-update" });
    }
}

window.pushCookieConsentContactFire = function () {
    if (isDev) {
        console.log("(dev) contact-success would be fired");
    }

    if (!isDev) {
        dataLayer.push({ event: "contact-success" });
    }
};

function loadGTM(gtmCode) {
    if (isDev) {
        console.log("(dev) loadGTM()");
    }

    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", gtmCode);
}
